@use '@angular/material' as mat;

@import "src/app/common/components/scroll-bar/scroll-bar.theme";
@import "src/app/common/components/button/button.theme";
@import "src/app/common/components/file-upload/file-upload.theme";
@import "src/app/common/components/avatar/avatar.theme";
@import "src/app/app/app.theme";

$primary-palette: mat.m2-define-palette(mat.$m2-deep-purple-palette);
$primary-accent: mat.m2-define-palette(mat.$m2-green-palette);
$primary-warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $primary-accent,
    warn: $primary-warn,
  ),
  density: 0,
));

@mixin set-components-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $text-color: mat.m2-get-color-from-palette($foreground, text);
  $text-light-color: rgba(255, 255, 255, 0.6);
  $text-disabled-color: mat.m2-get-color-from-palette($foreground, disabled-text);
  $text-secondary-color: mat.m2-get-color-from-palette($foreground, secondary-text);

  $gray-color: #a9a9a9;
  $green-color: #4caf50;
  $orange-color: orange;
  $red-color: red;
  $white-color: #FFFFFF;

  $primary-color: mat.m2-get-color-from-palette($primary);
  $primary-color-darkest: mat.m2-get-color-from-palette($primary, 900);
  $primary-lighter-color: mat.m2-get-color-from-palette($primary, lighter);
  $primary-color-lightest: mat.m2-get-color-from-palette($primary, 300);
  $primary-text-color: mat.m2-get-color-from-palette($primary, text);
  $accent-color: mat.m2-get-color-from-palette($accent);
  $accent-lighter-color: mat.m2-get-color-from-palette($accent, lighter);
  $accent-text-color: mat.m2-get-color-from-palette($accent, text);
  $warn-color: mat.m2-get-color-from-palette($warn, darker);
  $warn-text-color: mat.m2-get-color-from-palette($warn, text);

  $sidenav-background-color: mat.m2-get-color-from-palette($background, app-bar);
  $toolbar-background-color: mat.m2-get-color-from-palette($background, status-bar);
  $background-color-item-hover: mat.m2-get-color-from-palette($accent, lighter, 0.5);
  $background-color-dark-item-selected: mat.m2-get-color-from-palette($accent, darker, 0.5);

  .color-primary {
    color: $primary-color;
  }

  .color-warn {
    color: $warn-text-color;
  }

  .color-green {
    color: $green-color;
  }

  .background-green {
    background-color: $green-color;
  }

  .color-orange {
    color: $orange-color;
  }

  .color-red {
    color: $red-color;
  }

  .color-gray {
    color: $gray-color;
  }

  .text-normal-color {
    color: $text-color !important;
  }

  .text-secondary-color {
    color: $text-secondary-color;
  }

  .text-light-color {
    color: $white-color;
  }

  .text-disabled-color {
    color: $text-disabled-color;
  }

  .mat-menu-item[disabled=true] .mat-icon-no-color {
    color: $text-disabled-color;
  }

  .mat-snack-bar-container {
    color: $text-color;
  }
  .mat-snack-bar-container.error {
    color: $warn-text-color;

    .mat-button {
      color: $text-color;
    }
  }

  .mat-primary .mat-pseudo-checkbox-checked {
    &::after {
      color: $text-color !important;
    }
  }

  .content {
    background-color: $white-color;
  }

  @include scroll-bar-theme($accent-color, $accent-lighter-color, $primary-lighter-color);
  @include buttons-theme($primary-color);
  @include avatar-theme($primary-color, $text-light-color);
  @include file-upload-theme($primary-color, $background-color-item-hover, $background-color-dark-item-selected);
  @include app-component-theme($primary-color, $text-secondary-color, $accent-text-color, $sidenav-background-color, $toolbar-background-color);
}
